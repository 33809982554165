import SplitType from "split-type"
import { $App } from ".."
import gsap from "gsap"

export default class {
  app: $App
  constructor(app: $App) {
    this.app = app
    this.create()
  }

  create() {
    this.createScroll()
    this.createCopy()
  }

  createCopy() {
    const buttonAnime = gsap
      .timeline({ paused: true })
      .to(".footer__mail img", { autoAlpha: 0, duration: 0.2 })
      .to(".footer__mail i span", { x: "-1rem", duration: 0.2 }, "<")

    const popupAnime = gsap
      .timeline({ paused: true })
      .set(".footer__message", { width: "5.6rem", height: "5.6rem" })
      .set(".footer__message *", { autoAlpha: 0 })
      .to(".footer__message", { autoAlpha: 1, duration: 0.5 })
      .to(
        ".footer__message",
        { width: "38.6rem", duration: 0.35, delay: 0.1 },
        "<"
      )
      .to(
        ".footer__message *",
        { autoAlpha: 1, delay: innerWidth < 768 ? 0.15 : 0.3, duration: 0.25 },
        "<"
      )

    const button = window.$(".footer__mail a")
    const button2 = window.$(".header__mail a")
    const mail = window.$(".footer__mail a") as unknown as HTMLAnchorElement
    // button.onmouseenter = () => buttonAnime.play()
    // button.onmouseleave = () => buttonAnime.reverse()
    button.onclick = async (e) => {
      e.preventDefault()
      navigator.clipboard.writeText(mail.href.split(":")[1])
      buttonAnime.reverse()
      await popupAnime.restart()
      gsap.delayedCall(2, () => popupAnime.reverse())
    }
    if (button2)
      button2.onclick = async (e) => {
        e.preventDefault()
        navigator.clipboard.writeText(mail.href.split(":")[1])
        buttonAnime.reverse()
        await popupAnime.restart()
        gsap.delayedCall(2, () => popupAnime.reverse())
      }
  }

  createScroll() {
    new SplitType(".footer__mail a p")

    const isWork = window.$(".work__scroll")
    const isFile = window.$(".page__file")

    if (isWork && innerWidth < 768) return

    // const footer = gsap
    //   .timeline({
    //     paused: true,
    //     scrollTrigger: {
    //       trigger: ".footer__aside",
    //       start: "0% 75%",
    //       end: "100% 125%",
    //     },
    //   })
    //   .fromTo(
    //     ".footer__mail a p div div div",
    //     {
    //       yPercent: -100,
    //       clipPath: "inset(100% 0% 0% 0%)",
    //     },
    //     {
    //       yPercent: 0,
    //       clipPath: "inset(0% 0% 0% 0%)",
    //       duration: 0.5,
    //       stagger: { amount: 0.35 },
    //     }
    //   )
    //   .fromTo(
    //     ".footer__mail i",
    //     { opacity: 0 },
    //     { opacity: 1, duration: 0.75 },
    //     "<"
    //   )
    //   .fromTo(".footer__mail i", { width: "4.8rem" }, { width: "11.268rem" })
    //   .fromTo(
    //     ".footer__mail i *",
    //     { opacity: 0 },
    //     { opacity: 1, delay: 0.25, duration: 0.25 },
    //     "<"
    //   )
  }

  resize() {}

  destroy() {}

  navigate() {}
}

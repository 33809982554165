import gsap from "gsap"
import { $App } from ".."
import barba from "@barba/core"
import Scroll from "../classes/scroll"
import footer from "./footer"

export default class Nav {
  app: $App
  elements: HTMLElement[]
  pages: {
    home: string
    // longPage: string
  }
  titles: {
    home: string
    // "long-page": string
  }
  constructor(app: $App) {
    this.app = app
    this.create()
    this.pages = {
      home: "",
      // longPage: "long-page",
    }
    this.titles = {
      home: "",
      // "long-page": "",
    }
  }

  create() {
    const Nav = this
    const App = this.app

    barba.init({
      transitions: [
        {
          name: "transiontion name",
          before(data) {},
          async beforeLeave(data) {
            gsap.set(".header", { clearProps: true })
          },

          async leave(data) {},

          afterLeave(data) {},

          beforeEnter(data) {},

          async enter(data) {},

          async afterEnter(data) {
            App.page.destroy()
            App.template = data.next.namespace
            App.page = App.pages[data.next.namespace]
            App.page.create()
            App.scroll = null
            App.scroll = new Scroll(data.next.namespace)
            App.partials.footer = null
            App.partials.footer = new footer(App)
          },

          async after(data) {
            App.scroll.lenis.scrollTo(0, { immediate: true })
            App.scroll.lenis.scrollTo(0)
            await gsap.to("main", {})
          },
        },
      ],
    })

    this.createHam()
    this.createWorks()
  }

  createWorks() {
    if (this.app.template === "work") return
    const works = window.$(".header__nav a:nth-of-type(1)")

    works.onclick = (e) => {
      if (this.app.template === "home") {
        e.preventDefault()
        this.app.scroll.lenis.scrollTo("#work")
        return
      }
    }
  }

  createHam() {
    if (this.app.template === "work") return
    const ham = window.$(".header__ham")
    const menu = window.$(".header__open")
    const header = window.$(".header")

    ham.onclick = () => {
      ham.classList.toggle("active")
      menu.classList.toggle("active")
      header.classList.toggle("active")
    }
  }

  resize() {}
  destroy() {}

  navigate() {
    this.create()
  }

  async ready(href: string, push = true) {
    const [html, template] = await this.go(event)
    this.app.page.destroy()
    Object.values(this.app.partials).forEach((partial) => partial.destroy())
    this.app.content.innerHTML = html
    this.app.content.setAttribute("data-template", template)
    this.app.template = this.app.content.getAttribute("data-template")
    push && history.pushState({}, "", this.pages[template])
    this.app.page = this.app.pages[this.app.template]
    this.app.page.navigate()
    this.app.scroll.navigate(this.app.template)
    Object.values(this.app.partials).forEach((partial) => partial.navigate())
    document.title = this.titles[this.pages[this.app.template]]
    this.app.scroll.lenis.scrollTo(0, { immediate: true })
    this.menuClose()
  }

  async go({ target }) {
    const { href } = target
    const request = await fetch(href)
    if (request.ok) {
      const html = await request.text()
      const div = document.createElement("div")
      div.innerHTML = html
      const content = div.querySelector(".content")
      const template = content.getAttribute("data-template")
      return [content.innerHTML, template]
    } else {
      console.log(`could not fetch ${href}`)
    }
  }

  async back() {
    if (innerWidth < 760) return
    location.reload()
  }

  menuOpen() {
    this.app.scroll.lenis.stop()
  }

  async menuClose() {
    this.app.scroll.lenis.start()
  }
}

import Lenis from "@studio-freight/lenis"
import gsap from "gsap"
import { Observer, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger, Observer)

interface LenisEvent {
  animate: {
    value: number
    from: number
    to: number
    lerp: number
    duration: number
    isRunning: boolean
  }
  animatedScroll: number
  dimensions: { wrapper: Window; content: HTMLElement }
  direction: 1 | -1
  options: { wrapper: Window; content: HTMLElement }
  targetScroll: number
  time: number
  velocity: number
  __isLocked: boolean
  __isScrolling: boolean
  __isSmooth: true
  __isStopped: boolean
  actualScroll: number
  className: string
  isHorizontal: boolean
  isLocked: boolean
  isScrolling: boolean
  isSmooth: boolean
  isStopped: boolean
  limit: number
  progress: number
  scroll: number
}

export default class Scroll {
  lenis: Lenis
  scrollbar: gsap.core.Timeline
  observer: Observer
  navbar: gsap.core.Timeline
  constructor(page: string) {
    this.create(page)
  }

  create(page: string) {
    this.lenis = new Lenis({
      // wrapper: innerWidth >= 768 ? window : window.$(".app"),
      // smoothTouch: true,
      lerp: 0,
    })

    window.lenis = this.lenis

    let pos = 0
    this.scrollbar = gsap.timeline({ paused: true }).to(".header__scroll", {
      y: "95vh",
      ease: "linear",
      duration: 5,
    })
    const isBlog = window.$(".isBlog")
    const isWork = window.$(".work__scroll")
    const isFile = window.$(".page__file")
    if (isWork) {
      window.$(".work__scroll").onclick = () => {
        if (pos === 0) {
          this.lenis.scrollTo(".credits", {
            immediate: innerWidth < 761 ? true : false,
          })
          pos = 1
        } else {
          this.lenis.scrollTo(0, { immediate: innerWidth < 761 ? true : false })
          pos = 0
        }
      }
      this.scrollbar
        .to(".work__scroll", { opacity: 1, duration: 0 }, 0.01)
        .set(".work__scroll", { rotate: "180deg", duration: 0.2 }, 4.9)
        .to(
          ".work__scroll",
          { rotate: "0deg", duration: 3, ease: "steps(1)" },
          0
        )
    }

    if (isFile && !isBlog) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".work__hero",
            start: "0% 0%",
            scrub: true,
          },
        })
        .fromTo(
          ".header__nav__item",
          { color: "#ffffff" },
          { color: "#000000", delay: 1, duration: 0.1 }
        )
        .fromTo(
          ".header__home",
          { color: "#FFFFFF26" },
          { color: "#0000001C", delay: 1, duration: 0.1 },
          0
        )
        .fromTo(
          ".header__open",
          { backgroundColor: "#0B0C0D8cC4" },
          { backgroundColor: "#FEFDF88C", delay: 1, duration: 0.1 },
          0
        )
        .fromTo(
          ".header__mail",
          { filter: "invert(1)" },
          { filter: "invert(0)", delay: 1, duration: 0.1 },
          0
        )
        .fromTo(
          ".header__ham span",
          { backgroundColor: "#F4F3F2" },
          {
            backgroundColor: innerWidth >= 768 ? "#0B0C0D8c" : "#0B0C0D",
            delay: 1,
            duration: 0.1,
          },
          0
        )
    }

    if (isWork && !isBlog) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".work__hero",
            start: "0% 0%",
            end: innerWidth < 761 ? "100% 0%" : "105% 0%",
            scrub: true,
          },
        })
        .fromTo(
          "header",
          {
            backgroundColor: innerWidth >= 768 ? "#0B0C0D8c" : "#0B0C0D",
          },
          {
            backgroundColor: innerWidth >= 768 ? "#FFFFFF8c" : "#FFFFFF",
            delay: 3,
            duration: 0.1,
          }
        )
    }

    if (isWork && isBlog) {
      gsap.fromTo(
        "header",
        {
          backgroundColor: innerWidth >= 768 ? "#0B0C0D8c" : "#0B0C0D",
        },
        {
          backgroundColor: innerWidth >= 768 ? "#FFFFFF8c" : "#FFFFFF",
          delay: 3,
          duration: 0,
        }
      )
    }

    this.navbar = gsap.timeline({ paused: true }).fromTo(
      isWork ? "header" : ".header",
      {
        y: "0rem",
        ease: "power2.out",
      },
      {
        y: isWork ? "-10.25rem" : "-8.65rem",
        ease: "power2.in",
        duration: 1,
      }
    )

    this.observer = Observer.create({
      target: ".header__scroll__track",
      onDrag: this.onDrag.bind(this),
      onDragStart: (e) => e.target.classList.add("active"),
      onDragEnd: (e) => e.target.classList.remove("active"),
    })

    this.lenis.on("scroll", ScrollTrigger.update)
    let isPlayingNav = false
    let isReversingNav = false
    // this.lenis.on("scroll", ({ direction }: LenisEvent) => {
    //   if (direction > 0 && innerWidth >= 768) {
    //     if (isPlayingNav) return
    //     isPlayingNav = true
    //     gsap.delayedCall(0.9, () => {
    //       isPlayingNav = false
    //     })
    //     this.navbar.play()
    //   }

    //   if (direction < 0 && innerWidth >= 768) {
    //     if (isReversingNav) return
    //     isReversingNav = true
    //     gsap.delayedCall(0.9, () => {
    //       isReversingNav = false
    //     })
    //     this.navbar.reverse()
    //   }
    // })

    gsap.ticker.lagSmoothing(0)
    requestAnimationFrame(this.raf.bind(this))
  }

  navigate(page: string) {
    this.lenis.reset()
    this.create(page)
  }

  raf(time: number) {
    this.lenis.raf(time)
    this.scrollbar.progress(this.lenis.progress)
    requestAnimationFrame(this.raf.bind(this))
  }

  onDrag(e: globalThis.Observer) {
    this.lenis.scrollTo(
      (e.y / innerHeight) * this.lenis.dimensions.scrollHeight
    )
  }
}

import gsap from "gsap"
import { $App } from ".."
import { Draggable } from "gsap/all"
import { InertiaPlugin } from "gsap-trial/all"
gsap.registerPlugin(Draggable, InertiaPlugin)

export default class Work {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    const form = window.$("form") as unknown as HTMLFormElement

    const popupAnime = gsap
      .timeline({ paused: true })
      .set(".work__message", { width: "5.6rem", height: "5.6rem" })
      .set(".work__message *", { autoAlpha: 0 })
      .to(".work__message", { autoAlpha: 1, duration: 0.5 })
      .to(
        ".work__message",
        { width: "38.6rem", duration: 0.35, delay: 0.1 },
        "<"
      )
      .to(
        ".work__message *",
        { autoAlpha: 1, delay: innerWidth < 768 ? 0.15 : 0.3, duration: 0.25 },
        "<"
      )

    if (form) {
      form.onsubmit = async (e) => {
        e.preventDefault()
        if (form.querySelector("input").value === form.id) {
          form.parentElement.removeChild(form)
          await popupAnime.restart()
          gsap.delayedCall(2, () => popupAnime.reverse())
        } else {
          form.classList.add("active")
        }
      }
    }

    if (innerWidth >= 768) return

    const figures = window.$$(
      ".backstory figure, .challenge figure, .role figure, .insights figure, .reframe figure, .bts figure, .launch figure, .impact figure"
    )

    figures.forEach((figure) => {
      const x = Draggable.create(figure, {
        type: "x",
        bounds: figure.parentElement,
        onDragEnd: (a) => {
          if (x[0].x === x[0].minX || x[0].x === x[0].maxX) {
            gsap.to(x[0].target, {
              x: 0,
              ease: "back.out(1)",
              duration: 0.35,
            })
          }
        },
      })
    })

    const x = window.$(".header__x")
    const back = window.$(".header__controls a:nth-of-type(1)")

    if (x && back) {
      x.onclick = (e) => {
        e.preventDefault()
        history.back()
      }
      back.onclick = (e) => {
        // e.preventDefault()
        // history.back()
      }
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
